"use client"

import { useRouter } from 'next/navigation'

const memo = {}

const useRedirectBack = () => {
    const router = useRouter()

    const setRedirectPath = (path) => {
        memo.previousPath = path || window.location.href || '/'
    }

    const redirect = (explicitPath, backPath, memoFallbackPath) => {
        const path = explicitPath || memo.previousPath || memoFallbackPath
        if (backPath) setRedirectPath(backPath)
        if (typeof window !== 'undefined') {
            if (path) {
                router.push(path)
            } else {
                router.back()
            }
        }

        return null
    }

    const reload = () => router.refresh()

    return [redirect, setRedirectPath, reload]
}

export default useRedirectBack