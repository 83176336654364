"use client"

import * as SimpleSlider from '@components/slider/SimpleScrollSlider'
import OfferListItem from '@components/lists/OfferListItemNew'
import Container from '@components/layout/Container'
import BreadcrumbsContact, { // TODO: Remove breadcrumbs contact from here after migration
	ContactButtons,
} from './DisplayOfferBreadcrumbContactFragment'
import _Map from '@components/map/Map'
import Logo from '@components/display/user/Logo'
import SalonSubscriptionOptions from '@components/salon/SubscriptionOptions'
import Watch from '@components/display/offer/WatchDynamic'
import Button from '@components/button/Button'
import { MapIcon, ScrollToOfferButton } from './DisplayOfferClient'

import generationFor from '@utils/offers/generationFor'
import { shortTrimFor } from '@utils/offers/shortTrimFor'
import { buyCaptionFor, captionFor } from '@utils/alt/listAlt'
import { netPriceToGross } from '@utils/offers/netPriceToGross'
import formatPrice from '@utils/price/format'
import formatPriceRange from '@utils/price/formatPriceRange'
import userName from '@utils/user/userName'
import userAddress from '@utils/user/userAddress'
import salonLink from '@utils/links/salonLink'
import useT from '@hooks/useTranslation'

import deletedStyles from './DisplayOfferDeleted.module.css'
import priceStyles from './DisplayOfferPrice.module.css'
import userDetailStyles from './DisplayOfferUserDetails.module.css'

const logoStyle = {
	height: 60,
	padding: 0,
	width: 60,
}

const h1Style = {
	marginBottom: 12,
}

const h2Style = {
	marginBottom: -20,
}

function Title({ offer, style = {} }) {
	const caption = captionFor(offer)
	const generation = generationFor(offer)
	const shortTrim = shortTrimFor(offer)
	const isRent = Boolean(offer?.price?.rent)
	const tRent = useT('Do wynajęcia')
	const title = [caption, shortTrim || generation].filter(Boolean).join(' ')
	return (
		<>
			{isRent && <h2 style={{ ...h2Style, ...style }}>{tRent}:</h2>}
			<h1 style={{ ...h1Style, ...style }}>{title}</h1>
		</>
	)
}

function TitleBuy({ offer }) {
	return (
		<>
			<h2 style={h2Style}>{useT('Kupię')}</h2>
			<h1 style={h1Style}>{buyCaptionFor(offer)}</h1>
		</>
	)
}

function Price({ offer, price }) {
	const t = {
		installment: useT('Rata'),
		mth: useT('mc'),
		net: useT('Cena netto'),
		negotiable: useT('Do negocjacji'),
	}

	const { price: offerPrice } = offer || {}
	const { installment, price_is_negotiable, price_is_netto } =
		offerPrice || {}

	const [priceConvertedToGross,finalPrice] = netPriceToGross(offer)
	const finalPriceIsNet = price_is_netto && !priceConvertedToGross

	const additionals = [
		finalPriceIsNet && t.net,
		price_is_negotiable && t.negotiable,
	].filter(Boolean)
	const hasAdditionals = additionals.length > 0

	return (
		<>
			<span className={priceStyles.price}>
				{price || formatPrice(finalPrice, ' ')}&nbsp;
				<span className={priceStyles.currency}>
					{offerPrice?.currency || 'PLN'}
				</span>
				{Boolean(installment) && (
					<b style={{ display: 'block', marginBottom: 10 }}>
						{t.installment} {installment} PLN/{t.mth}
					</b>
				)}
				{hasAdditionals && (
					<b>
						{additionals.map((text) => (
							<span key={text}>{text}</span>
						))}
					</b>
				)}
			</span>
		</>
	)
}

function PriceBuy({ offer }) {
	return (
		<Price offer={offer} price={formatPriceRange(offer?.price)} />
	)
}

function SalonDetails({
	contactButtons = true,
	defaultAddress,
	inject,
	offer,
	offerLinkScrollTo,
	title,
	user,
	visitor,
	nofirmname,
}) {
	const { _id, has_logo } = user || {}

	const t = {
		no: useT('Brak danych'),
	}

	const displayName = userName(user) || t.no
	const displayAddress =
		offer?.tracking?.city ||
		defaultAddress ||
		userAddress(user, offer)

	return (
		<div className={userDetailStyles.details}>
			{title}
			{has_logo && (
				<>
					<Logo
						_id={_id}
						alt={displayName}
						has_logo={has_logo}
						style={logoStyle}
					/>
					<br />
				</>
			)}
			{!nofirmname && (
				<span className={userDetailStyles.name}>{displayName}</span>
			)}
			<MapIcon address={displayAddress} />
			<SalonSubscriptionOptions salon={user} user={visitor} />
			{offer && <Watch offer={offer} />}
			<br />
			{inject
				? inject
				: contactButtons && (
						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								gap: 12,
								paddingBottom: 20,
							}}
						>
							<ContactButtons offer={offer} recipient={user} />
						</div>
				  )}

			<SalonFullOfferLink
				scrollTo={offerLinkScrollTo}
				user={user}
				style={{
					background: '#fff',
					fontSize: 16,
					padding: 0,
					paddingLeft: 24,
					textDecoration: 'underline',
				}}
			/>
		</div>
	)
}

function SalonFullOfferLink({ scrollTo, user, ...rest }) {
	const { dealer } = user || {}
	const { subdomain } = dealer || {}
	const t = {
		see: useT('Zobacz pełną ofertę'),
	}
	if (!subdomain) return null
	const href = salonLink(subdomain)
	return scrollTo ? (
		<ScrollToOfferButton {...rest} scrollId={scrollTo} text={t.see} />
	) : (
		<Button {...rest} href={href}>
			{t.see}
		</Button>
	)
}

function Map({ offer, user, ...rest }) {
	const { location, dealer } = user || {}
	const { location: offerLocation, rent } = offer || {}
	const { location: dealerLocation } = dealer || {}

	const coordinates =
		rent?.reception_location?.coordinates ||
		dealerLocation?.coordinates ||
		offerLocation?.coordinates ||
		location?.coordinates

	return <_Map coordinates={coordinates} {...rest} />
}

function Similar({ firmOnly = true, offers, title, user }) {
	const text = useT(title || 'Pozostałe oferty tego sprzedawcy')
	const shouldHide =
		!offers ||
		!offers.length ||
		(firmOnly && !user?.dealer?.firm_name)

	if (shouldHide) return null

	return (
		<div style={{ background: 'var(--subtle-gray-alt)' }}>
			<Container>
				<br />
				<h2 style={{ textAlign: 'center' }}>{text}</h2>
				<br />
				<SimpleSlider.SimpleScrollSlider small>
					{offers.map((offer, idx) => (
						<SimpleSlider.Slide key={idx} small>
							<OfferListItem.Featured offer={offer} target="_blank" />
						</SimpleSlider.Slide>
					))}
				</SimpleSlider.SimpleScrollSlider>
				<br />
				<br />
				<br />
				<br />
			</Container>
		</div>
	)
}

function MarkDeleted({ children, isDeleted, offer }) {
	const t = useT('Sprzedane')

	isDeleted = Boolean(isDeleted || offer?.tracking?.deleted)

	return isDeleted ? (
		<div className={deletedStyles.wrap}>
			<span className={deletedStyles.sign}>{t}</span>
			{children}
		</div>
	) : (
		children
	)
}

const DisplayOffer = {}

DisplayOffer.Title = Title
DisplayOffer.TitleBuy = TitleBuy
DisplayOffer.Price = Price
DisplayOffer.PriceBuy = PriceBuy
DisplayOffer.SalonDetails = SalonDetails
DisplayOffer.Map = Map
DisplayOffer.Similar = Similar
DisplayOffer.MarkDeleted = MarkDeleted
DisplayOffer.BreadcrumbsContact = BreadcrumbsContact
DisplayOffer.ContactButtons = ContactButtons

export default DisplayOffer
export {
	Title,
	TitleBuy,
	Price,
	PriceBuy,
	Map,
	MarkDeleted,
	SalonDetails,
	Similar,
	BreadcrumbsContact,
	ContactButtons,
}
